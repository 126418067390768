<template>
  <div replace>
  </div>
</template>

<script>

export default {
  name: 'Replace',
  mounted() {
    this.$services.modal.block();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[receiver-sofort] {}
</style>